<template>
  <v-dialog v-model="visualizza" persistent max-width="600" transition="dialog-bottom-transition">
    <v-card tile>
      <v-toolbar class="grey lighten-3">
        <v-toolbar-title>Risultati della ricerca</v-toolbar-title>
        <v-spacer />
        <v-btn
          class="ml-0"
          color="primary"
          :disabled="selectedBancali.length == 0"
          @click.native="inserisci_f">
          Inserisci
        </v-btn>
        <v-btn icon @click.stop="hide()"><v-icon>close</v-icon></v-btn>
      </v-toolbar>
      <v-container fluid grid-list-xl>
        <v-layout>
          <v-flex xs12>
            <v-list two-line>
              <v-list-tile :key="index" v-for="(bancale, index) in bancali">
                <v-list-tile-action>
                  <v-checkbox v-model="selectedBancali" :value="bancale._id" />
                </v-list-tile-action>
                <v-list-tile-avatar v-on:click.stop>
                  <v-chip
                    color="green"
                    text-color="white"
                    v-if="!bancale.padre_id && bancale.posizione && bancale.posizione !== 'TMP'">
                    <span v-if="bancale.sede===1">C-</span>
                    <span v-else>P-</span>
                    {{bancale.posizione}}
                  </v-chip>
                  <v-chip
                    color="grey"
                    text-color="black"
                    v-if="!bancale.padre_id && bancale.posizione && bancale.posizione === 'TMP'">
                    <span v-if="bancale.sede===1">C-</span>
                    <span v-else>P-</span>
                    TMP
                  </v-chip>
                  <v-chip
                    color="yellow"
                    text-color="black"
                    v-if="!bancale.padre_id && !bancale.posizione">
                    <span v-if="bancale.sede===1">C-</span>
                    <span v-else>P-</span>
                    +
                  </v-chip>
                  <v-chip
                    color="red"
                    text-color="white"
                    v-if="bancale.padre_id">
                    <span v-if="bancale.sede===1">C-</span>
                    <span v-else>P-</span>
                    In uscita
                  </v-chip>
                </v-list-tile-avatar>
                <v-list-tile-content :key="'tile-'+bancale._id" style="margin-left:10px">
                  <v-list-tile-title>
                    <span v-if="bancale.progressivo">{{bancale.num_bancale}}</span>
                    <span v-else>{{bancale._id}}</span>
                    <span v-if="bancale.componenti && bancale.componenti.length">
                      {{writeCodici(bancale.componenti)}}
                    </span>
                  </v-list-tile-title>
                  <v-list-tile-sub-title>
                    <span class="text-uppercase">{{bancale.opera}} - {{bancale.componente}}</span>&nbsp;
                    <v-tooltip right v-if="bancale.more_data.length">
                      <span slot="activator" class="secondary--text">+{{bancale.more_data.length}}</span>
                      <span v-for="(item, index) in bancale.more_data" :key="'more-'+index">
                        {{item.descrizione}} - <span class="text-uppercase">{{item.codice}}</span><br />
                      </span>
                    </v-tooltip>
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
/* USO :
  html :
    <modal-risultati ref="risultati" v-bind:inserisci="aggiungiBancaliModale" />
  js :
  import modalRisultati from '@/components/magazzino/gestione/ModalRisultati'

  components: {
    'modal-risultati': modalRisultati
  }

  this.$refs.risultati.show()

*/

import _find from 'lodash/find'

const comp = {
  data: () => ({
    visualizza: false,
    bancali: [],
    selectedBancali: []
  }),
  props: {
    inserisci: Function
  },
  methods: {
    inserisci_f () {
      let ban = []
      for (let b of this.selectedBancali) {
        ban.push(_find(this.bancali, (el) => { return el._id === b }))
      }
      this.inserisci(ban)
    },
    show (b) {
      this.init(b)
      this.visualizza = true
    },
    hide () {
      this.visualizza = false
    },
    status () {
      return this.visualizza
    },
    writeCodici (comps) {
      let result = comps.map(a => a.codice).join()
      if (result) result = '(' + result + ')'
      return result
    },
    init (b) {
      this.selectedBancali = []
      let processData = function (bancale, item) {
        if (bancale.opera === null) {
          bancale.opera = item.opera
          bancale.componente = item.componente
          bancale.descrizione = item.descrizione
          bancale.codice = item.codice
        } else {
          bancale.more_data.push({ opera: item.opera, codice: item.codice, descrizione: item.descrizione })
        }
      }

      b.forEach(function (bancale) {
        bancale.opera = null
        bancale.prodotto = null
        bancale.codice_finito = null
        bancale.more_data = []

        if (bancale.componenti && bancale.componenti.length) {
          bancale.componenti.forEach(function (o) {
            processData(bancale, o)
          })
        }
      })
      this.bancali = b
    }
  }
}

export default comp
</script>
