<template>
  <v-dialog fullscreen v-model="visualizza" transition="dialog-bottom-transition" hide-overlay scrollable>
    <v-card tile>
      <v-form ref="formPrelievo" v-model="validFormPrelievo">
        <v-toolbar class="grey lighten-3">
          <v-toolbar-title>Modifica Prelievo</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click.stop="visualizza = false"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-container fluid grid-list-xl>
          <v-layout>
            <v-flex xs6>
              <date-picker
                v-model="prelv.data"
                label="Data"
                name="dataprelievo" />
              </v-flex>
            <v-flex xs6>
              <v-text-field
                label="N. Ordine"
                v-model="prelv.pedido"
                :rules="[$rules.maxlength(20)]" />
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12>
              <!--inizio parte duplicata-->
              <v-list three-line v-if="bancaliModale.length">
                <v-list-group
                  class="listIcon"
                  sub-group
                  no-action
                  v-for="(bancale, index) in bancaliModale"
                  v-model="bancale.active"
                  v-if="!bancale.deleted"
                  :key="'tile-'+bancale._id">
                  <v-list-tile slot="activator" avatar>
                    <div class="chipPosition">
                      <v-chip color="red" text-color="white" v-on:click.stop>
                        In uscita
                      </v-chip>
                      <v-icon
                        @click="$set(bancale, 'deleted', 1)"
                        v-if="canDeleteBancali() && !bancale.ddt_id && (bancale.bancale_padre.qta_prelevata < bancale.bancale_padre.qta_totale)"
                        color="error">
                        delete
                      </v-icon>
                      <v-spacer />
                    </div>
                    <v-list-tile-content class="ml-4">
                      <v-list-tile-title>
                        {{bancale.num_bancale}}
                      </v-list-tile-title>
                      <v-list-tile-sub-title>
                        <span v-if="bancale.componenti.length === 1">1 componente - </span>
                        <span v-else>{{bancale.componenti.length}} componenti - </span>
                        Qta {{bancale.qta_prelevata}}
                      </v-list-tile-sub-title>
                      <v-list-tile-sub-title>
                        <!--span v-if="bancale.qta_totale - bancale.qta_prelevata > 0">Prelievo parziale</span>
                        <span v-if="bancale.qta_totale - bancale.qta_prelevata === 0">Prelievo totale</span-->
                        <!-- span v-if="bancale.tipo === pr.val" v-for="pr in tipiPrelievo" :key="'tipoprel-'+pr.val"> {{pr.text}}</span -->
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-list-tile v-for="cmp in bancale.componenti" v-if="!cmp.deleted" :key="cmp.componente_id">
                    <v-list-tile-content ma-3>
                      <v-list-tile-title class="rowWrapper">
                        <v-layout row wrap class="rowWrapper-row">
                          <v-flex sm12 lg2>
                            <v-text-field
                              label="Opera"
                              v-model="cmp.opera"
                              disabled />
                          </v-flex>
                          <v-flex sm12 lg3>
                            <v-text-field
                              label="Descrizione"
                              v-model="cmp.descrizione"
                              disabled />
                          </v-flex>
                          <v-flex sm12 lg2>
                            <v-text-field
                              label="Codice"
                              v-model="cmp.codice"
                              disabled />
                          </v-flex>
                          <v-flex sm12 lg3>
                            <v-text-field
                              label="Componente"
                              v-model="cmp.componente"
                              disabled />
                          </v-flex>
                          <v-flex sm12 lg2>
                            <v-text-field
                              :name="'field-'+cmp._id"
                              v-model="cmp.qta_new"
                              :disabled="bancale.bancale_padre.qta_prelevata === bancale.bancale_padre.qta_totale"
                              :rules="[$rules.numeric, $rules.required, $rules.ngt(getTotale(bancale, cmp))]"
                              :label="'Qtà (su un totale di ' + getTotale(bancale, cmp) + ')'"
                            />
                            <span v-if="bancale.modalita === '1'">{{cmp.qta - cmp.qta_prelevata}}</span>
                          </v-flex>
                        </v-layout>
                      </v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <v-icon
                        @click="$set(cmp, 'deleted', 1)"
                        v-if="canDeleteComp(bancale) && !bancale.ddt_id && (bancale.bancale_padre.qta_prelevata < bancale.bancale_padre.qta_totale)"
                        color="error">
                        delete
                      </v-icon>
                    </v-list-tile-action>
                  </v-list-tile>
                  <v-divider :key="'divider-'+bancale._id" v-if="index < bancaliModale.length - 1" inset></v-divider>
                </v-list-group>
              </v-list>
              <!--fine parte duplicata-->
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12>
              <v-btn
                class="ml-0"
                color="primary"
                :disabled="!validFormPrelievo"
                @click.native="savePrelievo()">
                Salva
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
/* USO :
html :
    <modifica-prelievo ref="modificaPrelievo" v-bind:getBancali="getBancali" />
  js :
  import modificaPrelievo from '@/components/magazzino/gestione/ModificaPrelievo'

  components: {
    modificaPrelievo
  }
*/

import { $EventBus } from '@/eventBus'
import datePicker from '@/components/common/DatePicker'

const comp = {
  props: {
    getBancali: Function
  },
  data: () => ({
    prelievo_id: null,
    prelv: {},
    visualizza: false,
    bancaleModale: {},
    bancaliModale: [],
    bancaliLoading: false,
    tipiPrelievo: [
      { val: 0, text: 'Pedido' },
      { val: 1, text: 'Traslado' },
      { val: 2, text: 'Muestra' },
      { val: 3, text: 'Prelievo' }
    ],
    validFormPrelievo: false
  }),
  methods: {
    show () {
      this.visualizza = true
    },
    hide () {
      this.visualizza = false
    },
    canDeleteBancali () {
      return this.bancaliModale.filter(b => { return !b.deleted && !b.ddt_id }).length > 1
    },
    canDeleteComp (bancale) {
      return bancale.componenti.filter(c => { return !c.deleted }).length > 1 && !bancale.ddt_id
    },
    savePrelievo: function () {
      this.prelv.bancali = this.bancaliModale
      this.$plsqlMethod('gestione', 'update_prelievo', this.prelv)
        .then(response => {
          this.visualizza = false
          this.getBancali()
        })
        .catch(err => {
          console.log(err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore prelievo Bancali' })
        })
    },
    resetVars: function (bancale) {
      this.prelievo_id = bancale.prelievo_id
      this.$plsqlMethod('gestione', 'get_prelievo', { prelievo_id: this.prelievo_id })
        .then(response => {
          this.prelv = response.data
          this.prelv.prelievo_id = bancale.prelievo_id
          this.bancaliModale = this.prelv.bancali
          this.bancaliModale.forEach(function (b) {
            b.componenti.forEach(function (c) { c.qta_new = c.qta })
          })
        })
        .catch(err => {
          console.log(err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore lettura Prelievo' })
        })
    },
    getComponenteOriginale: function (b, id) {
      return b.bancale_padre.componenti.find(function (el) { return el.componente_id === id })
    },
    nvl: function (val, otherVal) {
      if (!val) return otherVal

      return val
    },
    getTotale: function (b, c) {
      let o = this.getComponenteOriginale(b, c.componente_id)
      return parseInt(o.qta) - parseInt(o.qta_prelevata) + parseInt(this.nvl(c.qta, 0))
    }
  },
  components: {
    datePicker
  }
}

export default comp
</script>
<style scoped>
  .v-list__group {
    border-bottom: 1px solid rgba(0,0,0,0.12);
  }
  .rowWrapper {
    height: auto;
    line-height: inherit;
    padding: 10px 0;
  }
  .rowWrapper-row {
    height: 90px;
    margin-top: 0!important;
  }
  .v-form > .container {
    padding: 24px;
  }
  .chipPosition {
    min-width: 85px;
    text-align: center;
  }
</style>
