<template>
  <span>
    <v-card-text>
      <v-flex>
        <v-layout>
          <v-flex xs12>
            <v-text-field
              class="ma-12"
              label="Posizione"
              :disabled="intmp"
              :rules="[$rules.maxlength(6)]"
              v-model="bancale.posizione" />
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-checkbox v-model="intmp" @change="bancale.posizione = intmp ? 'TMP' : null" label="TMP" />
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12 class="pa-2">
            <v-btn
              class="ml-0"
              color="primary"
              :disabled="(obbligatorio && !bancale.posizione) || (bancale.posizione && bancale.posizione.length>6)"
              @click.native="setPosizione()">
              Salva
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-card-text>
  </span>
</template>

<script>
import { $EventBus } from '@/eventBus'

const comp = {
  props: {
    bancale: Object,
    evento: '',
    intmp: false,
    obbligatorio: true
  },
  methods: {
    setPosizione () {
      $EventBus.$emit(this.evento)
    }
  }
}

export default comp
</script>
