<template>
  <v-dialog fullscreen v-model="visualizza" transition="dialog-bottom-transition" hide-overlay scrollable>
    <v-card>
      <v-toolbar class="grey lighten-3">
        <v-toolbar-title>Dettaglio</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click.stop="visualizza = false"><v-icon>close</v-icon></v-btn>
      </v-toolbar>
      <v-container fluid grid-list-xl>
        <form class="mt-4">
          <v-layout>
            <v-flex xs6>
              <date-picker
                class="mt-0"
                v-model="ricerca.data"
                label="Data di riferimento"
                name="data" />
            </v-flex>
            <v-flex xs6>
              <v-select
                label="Cliente"
                :items="clienti"
                item-text="ragione_sociale"
                item-value="cliente_id"
                v-model="ricerca.cliente_id" />
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12>
              <v-btn
                class="ml-0"
                color="primary"
                :disabled="!ricerca.data"
                @click.native="cerca()">
                Crea report
              </v-btn>
              <v-btn
                class="ml-0"
                color="primary"
                :disabled="opere.length < 1"
                @click.native="stampa()">
                Stampa in PDF
              </v-btn>
              <v-btn
                class="ml-0"
                color="primary"
                :disabled="opere.length < 1"
                @click.native="excel()">
                Export in Excel
              </v-btn>
            </v-flex>
          </v-layout>
        </form>
        <v-layout v-if="cliente">
          <v-flex xs12>
            Cliente selezionato : {{cliente}}
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-list v-if="opere.length">
              <template v-for="(opera, index) in opere">
                <v-list-tile-content :key="'tile-'+index">
                  <v-list-tile-title>
                    {{opera.opera}} = {{opera.bancali}} plt
                  </v-list-tile-title>
                </v-list-tile-content>
              </template>
            </v-list>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-chip
              color="blue"
              text-color="white"
              v-if="opere.length">
              Totale {{totale}}
            </v-chip>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { $EventBus } from '@/eventBus'
import datePicker from '@/components/common/DatePicker'

export default {
  data: () => ({
    opere: [],
    visualizza: false,
    totale: 0,
    cliente: null,
    clienti: [],
    ricerca: {}
  }),
  components: {
    datePicker
  },
  methods: {
    show () {
      this.visualizza = true
      this.opere = []
      this.totale = 0
      this.cliente = undefined
      this.$set(this.ricerca, 'data', null)
      this.$set(this.ricerca, 'cliente', null)
    },
    hide () {
      this.visualizza = false
    },
    cerca (id) {
      this.totale = 0
      this.$plsqlMethod('gestione', 'informe', this.ricerca)
        .then(response => {
          this.opere = response.data.opere
          for (let o of this.opere) this.totale += o.bancali
          this.cliente = response.data.cliente
        })
        .catch(err => {
          console.log('errore client', err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore lettura report' })
        })
    },
    stampa () {
      this.$http({
        method: 'post',
        url: '/pdf/informe',
        responseType: 'blob',
        data: { opere: this.opere, totale: this.totale, ricerca: this.ricerca, cliente: this.cliente }
      }).then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        this.$openFileNewTab(blob) // con adblocker picche ocio
      }).catch((err) => {
        console.log(err)
        $EventBus.$emit('message', { type: 'error', text: 'Impossibile creare la stampa Dettaglio' })
      })
    },
    excel () {
      this.$http({
        method: 'post',
        url: '/excel/informe',
        responseType: 'blob',
        data: { opere: this.opere, totale: this.totale, ricerca: this.ricerca, cliente: this.cliente }
      }).then((response) => {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        this.$openFileNewTab(blob, 'dettaglio.xlsx') // con adblocker picche ocio
      }).catch((err) => {
        console.log(err)
        $EventBus.$emit('message', { type: 'error', text: 'Impossibile creare l\'excel del Dettaglio' })
      })
    }
  },
  mounted () {
    // this.getClienti()
  }
}
</script>
<style scoped>
  .v-list__group {
    border-bottom: 1px solid rgba(0,0,0,0.12);
  }
  .rowWrapper {
    height: auto;
    line-height: inherit;
    padding: 10px 0;
  }
  .rowWrapper-row {
    height: 90px;
    margin-top: 0!important;
  }
</style>
