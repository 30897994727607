<template>
  <v-dialog fullscreen v-model="visualizza" transition="dialog-bottom-transition" hide-overlay scrollable>
    <v-card tile>
      <v-form ref="formPrelievo" v-model="validFormPrelievo">
        <v-toolbar class="grey lighten-3">
          <v-toolbar-title>Prelievo bancali</v-toolbar-title>
          <v-spacer />
          <v-chip small color="grey dark-3" text-color="white">
            Bancali selezionati: {{bancaliModale.length}}
          </v-chip>
          <v-btn icon @click.stop="hide()"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-container fluid grid-list-xl>
          <v-layout>
            <v-flex xs3 lg3 xl3>
              <date-picker
                v-model="prelv.data"
                label="Data"
                name="dataprelievo" />
              </v-flex>
            <v-flex xs4 lg3 xl3>
              <v-text-field
                label="N. Ordine"
                v-model="prelv.pedido"
                :rules="[$rules.maxlength(20)]" />
            </v-flex>
            <v-flex xs4 lg3 xl3>
                <v-select
                  label="Tipo*"
                  :items="tipiPrelievo"
                  item-text="text"
                  item-value="val"
                  v-model="prelv.tipo"
                  :rules="[$rules.required]" />
            </v-flex>
            <v-flex xs1 lg3 xl3>
              <v-btn color="info" @click="stampa()">Stampa</v-btn>
            </v-flex>
          </v-layout>
        <v-layout>
          <v-flex class="pa-0 mt-4 ml-4 pl-2">
            Ricerca
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex>
            <v-layout class="ml-4">
              <v-flex xs3>
                <v-text-field
                  label="Cerca ID bancale"
                  v-model="ricerca.bancale_id" />
              </v-flex>
              <v-flex xs3>
                <v-select
                  label="Cliente"
                  :items="clienti"
                  item-text="ragione_sociale"
                  item-value="cliente_id"
                  v-model="ricerca.cliente_id" />
              </v-flex>
              <v-flex xs3>
                <v-text-field
                  label="Opera"
                  v-model="ricerca.opera" />
              </v-flex>
              <v-flex xs3>
                <v-text-field
                  label="Descrizione"
                  v-model="ricerca.descrizione" />
              </v-flex>
            </v-layout>
            <v-layout class="ml-4">
              <v-flex xs3>
                <v-text-field
                  label="Componente"
                  v-model="ricerca.componente" />
              </v-flex>
              <v-flex xs3>
                <v-text-field
                  label="Codice Stock"
                  v-model="ricerca.codice" />
              </v-flex>
              <v-flex xs3>
                <v-select
                  label="Sede*"
                  :items="sedi"
                  v-model="ricerca.sede"
                  :rules="[$rules.required]" />
              </v-flex>
              <v-flex xs3 class="pa-3">
                <v-btn
                  class="ml-0"
                  color="primary"
                  :disabled="!ricerca.sede"
                  @click.native="cerca()">
                  Filtra
                </v-btn>
                <v-btn @click="reset_cerca()">
                  Ripristina
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
          <v-layout>
            <v-flex xs12>
              <!--inizio parte duplicata-->
              <v-list three-line v-if="bancaliModale.length">
                <v-list-group
                  class="listIcon"
                  sub-group
                  no-action
                  v-for="(bancale, index) in bancaliModale"
                  v-model="bancale.active"
                  :key="'tile-'+bancale._id">
                  <v-list-tile slot="activator" avatar>
                    <div v-on:click.stop class="chipPosition">
                      <v-chip
                        color="green"
                        text-color="white"
                        v-if="!bancale.padre_id && bancale.posizione && bancale.posizione !== 'TMP'">
                        <span v-if="bancale.sede===1">C-</span>
                        <span v-else>P-</span>
                        {{bancale.posizione}}
                      </v-chip>
                      <v-chip
                        color="grey"
                        text-color="black"
                        v-if="!bancale.padre_id && bancale.posizione && bancale.posizione === 'TMP'">
                        <span v-if="bancale.sede===1">C-</span>
                        <span v-else>P-</span>
                        TMP
                      </v-chip>
                      <v-chip
                        color="yellow"
                        text-color="black"
                        v-if="!bancale.padre_id && !bancale.posizione">
                        <span v-if="bancale.sede===1">C-</span>
                        <span v-else>P-</span>
                        +
                      </v-chip>
                      <v-chip
                        color="red"
                        text-color="white"
                        v-if="bancale.padre_id">
                        <span v-if="bancale.sede===1">C-</span>
                        <span v-else>P-</span>
                        In uscita
                      </v-chip>
                    </div>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        <span v-if="bancale.progressivo">{{bancale.num_bancale}}</span>
                        <span v-else>{{bancale._id}}</span>
                      </v-list-tile-title>
                      <v-list-tile-sub-title>
                        <span v-if="bancale.componenti.length === 1">1 componente - </span>
                        <span v-if="bancale.componenti.length != 1">{{bancale.componenti.length}} componenti - </span>
                        Qta
                        <span v-if="bancale.padre_id">{{bancale.qta_prelevata}}</span>
                        <span v-else>{{bancale.qta_totale}}</span>
                        <span v-if="bancale.prelievi"> - </span>
                        <span v-if="bancale.prelievi === 1">1 prelievo</span>
                        <span v-if="bancale.prelievi > 1">{{bancale.prelievi}} prelievi</span>
                      </v-list-tile-sub-title>
                      <v-list-tile-sub-title>
                        <span @click.stop="openTipoPrelievo(bancale)" v-if="bancale.modalita == '0'">Prelievo parziale</span>
                        <span @click.stop="openTipoPrelievo(bancale)" v-if="bancale.modalita == '1'">Prelievo totale</span>
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                    <v-btn icon ripple>
                      <v-icon @click.stop="removeBancale(index)" color="error">delete</v-icon>
                    </v-btn>
                  </v-list-tile-action>
                  </v-list-tile>
                  <v-list-tile v-for="comp in bancale.componenti" :key="comp.componente_id">
                    <v-list-tile-content ma-3>
                      <v-list-tile-title class="rowWrapper">
                        <v-layout row wrap class="rowWrapper-row">
                          <v-flex sm12 lg2>
                            <v-text-field
                            label="Opera"
                            v-model="comp.opera"
                            disabled />
                          </v-flex>
                          <v-flex sm12 lg2>
                            <v-text-field
                            label="Descrizione"
                            v-model="comp.descrizione"
                            disabled />
                          </v-flex>
                          <v-flex sm12 lg1>
                            <v-text-field
                              label="Codice"
                              v-model="comp.codice"
                              disabled />
                          </v-flex>
                          <v-flex sm12 lg2>
                            <v-text-field
                              label="Componente"
                              v-model="comp.componente"
                              disabled />
                          </v-flex>
                          <v-flex sm12 lg2>
                            <v-text-field
                              label="Note"
                              v-model="comp.note"
                              disabled />
                          </v-flex>
                          <v-flex sm12 lg1>
                            <v-text-field
                              v-if="bancale.modalita === '0' && comp.qta - comp.qta_prelevata"
                              label="Quantità"
                              :name="'field-'+comp._id"
                              v-model="comp.qta_da_prelevare"
                              :rules="[$rules.numeric, $rules.ngt(comp.qta - comp.qta_prelevata)]"
                              />
                            <span v-if="bancale.modalita === '1'">{{comp.qta - comp.qta_prelevata}}</span>
                          </v-flex>
                          <v-flex sm12 lg1>
                            Su totale di {{comp.qta - comp.qta_prelevata}}
                          </v-flex>
                        </v-layout>
                      </v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-divider :key="'divider-'+bancale._id" v-if="index < bancaliModale.length - 1" inset></v-divider>
                </v-list-group>
              </v-list>
              <!--fine parte duplicata-->
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12>
              <v-btn
                class="ml-0"
                color="primary"
                :disabled="cantSave()"
                @click.native="savePrelievo()">
                Prelievo
              </v-btn>
              <v-alert
                value="true"
                type="error"
                v-if="bancaliModale.length && cantSave()">
                Non è possibile effettuare prelievi da bancali di Sedi diverse.
              </v-alert>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-card>
    <!-- tipo prelievo -->
    <v-dialog v-model="showTipoPrelievo" transition="dialog-bottom-transition" :overlay="false" max-width="900" scrollable>
      <v-card>
        <v-toolbar class="grey lighten-3" card>
          <v-toolbar-title>
            Prelievo
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon @click.stop="showTipoPrelievo = false"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-flex>
            <v-layout>
              <v-flex xs12>
                <v-radio-group v-model="bancaleModale.modalita">
                  <v-radio
                    label="Prelievo totale"
                    value="1"
                  ></v-radio>
                  <v-radio
                    label="Prelievo parziale"
                    value="0"
                  ></v-radio>
                </v-radio-group>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs12 class="pa-2">
                <v-btn
                  v-if="!bancaleModale._id"
                  class="ml-0"
                  color="primary"
                  @click.native="setTipoPrelievo()">
                  Salva
                </v-btn>
                <v-btn
                  v-if="bancaleModale._id"
                  class="ml-0"
                  color="primary"
                  @click.native="modificaTipoPrelievo()">
                  Modifica
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-card-text>
      </v-card>
    </v-dialog>
    <modal-risultati ref="risultati" v-bind:inserisci="aggiungiBancaliModale" />
  </v-dialog>
</template>

<script>
/* USO :
html :
    <prelievo ref="prelievo" />
  js :
  import prelievo from '@/components/magazzino/gestione/Prelievo'

  components: {
    'prelievo': prelievo
  }

  this.$refs.prelievo.show()
*/

import _clone from 'lodash/clone'
import _sortBy from 'lodash/sortBy'
import { $EventBus } from '@/eventBus'
import datePicker from '@/components/common/DatePicker'
import modalRisultati from '@/components/magazzino/gestione/ModalRisultati'

const comp = {
  data: () => ({
    visualizza: false,
    bancaleModale: {},
    bancaliModale: [],
    tmpBancaliModale: [],
    bancaliLoading: false,
    ricerca: { },
    showTipoPrelievo: false,
    tipiPrelievo: [
      { val: 0, text: 'Pedido' },
      { val: 1, text: 'Traslado' },
      { val: 2, text: 'Muestra' },
      { val: 3, text: 'Prelievo' }
    ],
    prelv: {
      data: new Date(Date.now()).toLocaleString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' }).slice(0, 10)
    },
    stati: [
      { val: 1, text: 'Posizionati' },
      { val: 2, text: 'Da posizionare' },
      { val: 3, text: 'TMP' }
    ],
    clienti: [],
    validFormPrelievo: false,
    sedi: [{ value: 1, text: 'Cormano' }, { value: 2, text: 'Paderno' }]
  }),
  methods: {
    show () {
      this.reset_cerca()
      this.visualizza = true
    },
    hide () {
      this.visualizza = false
    },
    cerca () {
      this.bancaliLoading = true

      let o = {
        what: this.search,
        page: 1,
        rows4page: 50000
      }
      if (!this.isEmpty(this.ricerca)) o.ricerca = this.ricerca

      this.$plsqlMethod('gestione', 'search_by_id', o)
        .then(response => {
          response.data = response.data.filter(d => !this.bancaliModale.find(function (b) { return b._id === d._id }))
          this.$refs.risultati.show(response.data)
        })
        .catch(err => {
          $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
        })
    },
    stampa () {
      this.prelv.bancali = _sortBy(this.bancaliModale, ['posizione'])
      let toPrint = this.prelv
      this.$http({
        method: 'post',
        url: `/pdf/prelievo`,
        responseType: 'blob',
        data: toPrint
      }).then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        this.$openFileNewTab(blob) // con adblocker picche ocio
      }).catch((err) => {
        console.log(err)
        $EventBus.$emit('message', { type: 'error', text: 'Impossibile creare la stampa dei bancali' })
      })
    },
    selectedBancaleAutocomplete () {
      this.bancaleModale = _clone(this.bancaleAutocomplete)
      this.bancaleModale.modalita = '0'
      this.bancaleModale.in_prelievo = true
      this.showTipoPrelievo = true
    },
    openTipoPrelievo (b) {
      this.bancaleModale = b
      this.showTipoPrelievo = true
    },
    aggiungiBancaliModale: function (b) {
      this.tmpBancaliModale = []
      for (let item of b) {
        if (!this.isEmpty(item)) {
          let c = _clone(item)
          c.modalita = '0'
          c.in_prelievo = true
          this.tmpBancaliModale.push(c)
        }
      }
      this.$refs.risultati.hide()
      this.showTipoPrelievo = true
    },
    removeBancale: function (idx) {
      this.bancaliModale.splice(idx, 1)
    },
    reset_cerca () {
      if (!this.isEmpty(this.ricerca)) {
        this.ricerca = {}
      }
      this.ricerca.sede = 1
    },
    savePrelievo: function () {
      this.prelv.bancali = this.bancaliModale
      this.$plsqlMethod('gestione', 'prelievo', this.prelv)
        .then(response => {
          $EventBus.$emit('message', { type: 'success', text: 'Prelievo andato a buon fine.' })
          this.visualizza = false
          $EventBus.$emit('reload')
        })
        .catch(err => {
          $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
        })
    },
    cantSave: function () {
      if ([0, 1, 2, 3].indexOf(this.prelv.tipo) < 0 || this.bancaliModale.length < 1) return true
      const unique = [...new Set(this.bancaliModale.map(bancale => bancale.sede))]
      return unique.length > 1
    },
    modificaTipoPrelievo: function () {
      this.showTipoPrelievo = false
    },
    setTipoPrelievo: function () {
      for (let bancale of this.tmpBancaliModale) {
        if (this.bancaleModale.modalita === '1') {
          for (let c of bancale.componenti) {
            c.qta_da_prelevare = c.qta - c.qta_prelevata
          }
        }
        bancale.modalita = this.bancaleModale.modalita
        if (bancale.in_prelievo) {
          delete bancale.in_prelievo
          this.bancaliModale.push(_clone(bancale))
          this.bancaliModale = _sortBy(this.bancaliModale, ['posizione'])
        }
      }
      this.tmpBancaliModale = []
      this.bancaleModale = {}
      this.showTipoPrelievo = false
    },
    resetVars: function () {
      this.bancaleModale = {}
      this.bancaliModale = []
      this.bancaliLoading = false
      this.prelv = {
        data: new Date(Date.now()).toLocaleString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' }).slice(0, 10)
      }
      this.validFormPrelievo = false
    },
    isEmpty (obj) {
      return (obj.keys && obj.keys.length)
    }
  },
  components: {
    datePicker,
    'modal-risultati': modalRisultati
  }
}

export default comp
</script>

<style scoped>
  .v-list__group {
    border-bottom: 1px solid rgba(0,0,0,0.12);
  }
  .rowWrapper {
    height: auto;
    line-height: inherit;
    padding: 10px 0;
  }
  .rowWrapper-row {
    height: 90px;
    margin-top: 0!important;
  }
  .v-form > .container {
    padding: 24px;
  }
  .chipPosition {
    min-width: 85px;
    text-align: center;
  }
</style>
