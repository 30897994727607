<template>
  <v-dialog fullscreen v-model="visualizza" transition="dialog-bottom-transition" hide-overlay scrollable>
    <v-card tile>
      <v-toolbar class="grey lighten-3">
        <v-toolbar-title>Unione bancali</v-toolbar-title>
        <v-spacer />
        <v-chip small color="grey dark-3" text-color="white">
          Bancali selezionati: {{bancaliModale.length}}
        </v-chip>
        <v-btn icon @click.stop="hide()"><v-icon>close</v-icon></v-btn>
      </v-toolbar>
      <v-container fluid grid-list-xl>
        <v-layout>
          <v-flex class="pa-0 ml-4 pl-2">
              Ricerca
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex>
            <v-layout class="ml-4">
              <v-flex xs12 sm6 md3>
                <v-text-field
                  label="Cerca ID bancale"
                  v-model="ricerca.bancale_id" />
              </v-flex>
              <v-flex xs12 sm6 md3>
                <v-select
                  label="Cliente"
                  :items="clienti"
                  item-text="ragione_sociale"
                  item-value="cliente_id"
                  v-model="ricerca.cliente_id" />
              </v-flex>
              <v-flex xs12 sm6 md3>
                <v-text-field
                  label="Opera"
                  v-model="ricerca.opera" />
              </v-flex>
              <v-flex xs12 sm6 md3>
                <v-text-field
                  label="Descrizione"
                  v-model="ricerca.descrizione" />
              </v-flex>
            </v-layout>
            <v-layout class="ml-4">
              <v-flex xs12 sm6 md3>
                <v-text-field
                  label="Componente"
                  v-model="ricerca.componente" />
              </v-flex>
              <v-flex xs12 sm6 md3>
                <v-text-field
                  label="Codice Stock"
                  v-model="ricerca.codice" />
              </v-flex>
              <v-flex xs12 sm6 md3>
                <v-select
                  label="Stato"
                  :items="stati"
                  item-text="text"
                  item-value="val"
                  v-model="ricerca.stato" />
              </v-flex>
              <v-flex xs12 sm6 md3 class="pa-3">
                <v-btn
                  class="ml-0"
                  color="primary"
                  @click.native="cerca()">
                  Filtra
                </v-btn>
                <v-btn
                  @click="reset_cerca()">
                  Ripristina
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-list two-line v-if="bancaliModale.length">
              <v-list-group
                class="listIcon"
                sub-group
                no-action
                v-for="(bancale, index) in bancaliModale"
                v-model="bancale.active"
                :key="'tile-'+bancale._id"
                >
                <v-list-tile slot="activator" avatar>
                  <v-list-tile-avatar v-on:click.stop>
                    <v-chip
                      color="green"
                      text-color="white"
                      v-if="!bancale.padre_id && bancale.posizione && bancale.posizione !== 'TMP'">
                      {{bancale.posizione}}
                    </v-chip>
                    <v-chip
                      color="grey"
                      text-color="black"
                      v-if="!bancale.padre_id && bancale.posizione && bancale.posizione === 'TMP'">
                      TMP
                    </v-chip>
                    <v-chip
                      color="yellow"
                      text-color="black"
                      v-if="!bancale.padre_id && !bancale.posizione">
                      +
                    </v-chip>
                    <v-chip
                      color="red"
                      text-color="white"
                      v-if="bancale.padre_id">
                      In uscita
                    </v-chip>
                  </v-list-tile-avatar>
                  <v-list-tile-content style="margin-left:10px">
                    <v-list-tile-title>
                      <span v-if="bancale.progressivo">{{bancale.num_bancale}}</span>
                      <span v-else>{{bancale._id}}</span>
                    </v-list-tile-title>
                    <v-list-tile-sub-title>
                      Qta
                      <span v-if="bancale.padre_id">{{bancale.qta_prelevata}}</span>
                      <span v-else>{{bancale.qta_totale}}</span>
                      <span v-if="bancale.prelievi"> - </span>
                      <span v-if="bancale.prelievi === 1">1 prelievo</span>
                      <span v-if="bancale.prelievi > 1">{{bancale.prelievi}} prelievi</span>
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                  <v-list-tile-action>
                    <v-btn icon ripple>
                      <v-icon @click.stop="removeBancale(index)" color="error">delete</v-icon>
                    </v-btn>
                  </v-list-tile-action>
                </v-list-tile>
                <v-list-tile v-for="comp in bancale.componenti" :key="comp.componente_id">
                  <v-list-tile-content>
                    <v-list-tile-title class="rowWrapper">
                      <v-layout row wrap class="rowWrapper-row">
                        <v-flex sm12 lg2>
                          <v-text-field
                            label="Opera"
                            v-model="comp.opera"
                            disabled />
                        </v-flex>
                        <v-flex sm12 lg2>
                          <v-text-field
                            label="Descrizione"
                            v-model="comp.descrizione"
                            disabled />
                        </v-flex>
                        <v-flex sm12 lg1>
                          <v-text-field
                            label="Codice"
                            v-model="comp.codice"
                            disabled />
                        </v-flex>
                        <v-flex sm12 lg3>
                          <v-text-field
                            label="Componente"
                            v-model="comp.componente"
                            disabled />
                        </v-flex>
                        <v-flex sm12 lg3>
                          <v-text-field
                            label="Note"
                            v-model="comp.note"
                            disabled />
                        </v-flex>
                        <v-flex sm12 lg1>
                          <v-text-field
                            label="Qtà"
                            v-model="comp.qta"
                            disabled />
                        </v-flex>
                      </v-layout>
                    </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider :key="'divider-'+bancale._id" v-if="index < bancaliModale.length - 1" inset></v-divider>
              </v-list-group>
            </v-list>
            <!--fine parte duplicata-->
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12 sm6 md4 lg3 xl2>
            <posizione v-bind:bancale="bancale" evento="unione-posizione" />
          </v-flex>
        </v-layout>
        <modal-risultati ref="risultati" v-bind:inserisci="aggiungiBancaliModale" />
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
/* USO :
html :
    <unione ref="unione" />
  js :
  import unione from '@/components/magazzino/gestione/Unione'

  components: {
    'unione': unione
  }

  this.$refs.unione.show()
*/

import posizione from '@/components/magazzino/gestione/Posizione'
import modalRisultati from '@/components/magazzino/gestione/ModalRisultati'
import _clone from 'lodash/clone'
import { $EventBus } from '@/eventBus'

const comp = {
  data: () => ({
    visualizza: false,
    bancaleModale: {},
    bancaliModale: [],
    bancaliRicerca: [],
    bancaliLoading: false,
    searchBancali: null,
    ricerca: {},
    bancale: {},
    stati: [
      { val: 1, text: 'Posizionati' },
      { val: 2, text: 'Da posizionare' },
      { val: 3, text: 'TMP' }
    ],
    clienti: []
  }),
  methods: {
    show () {
      this.reset_cerca()
      this.visualizza = true
    },
    hide () {
      this.visualizza = false
    },
    reset_cerca () {
      if (!this.isEmpty(this.ricerca)) {
        this.ricerca = {}
      }
    },
    cerca () {
      this.bancaliLoading = true

      let o = {
        what: this.search,
        page: 1,
        rows4page: 50000
      }
      if (!this.isEmpty(this.ricerca)) o.ricerca = this.ricerca

      // this.$plsqlReadAll('gestione', o)
      this.$plsqlMethod('gestione', 'search_by_id', o)
        .then(response => {
          this.$refs.risultati.show(response.data)
        })
        .catch(err => {
          console.log('errore client', err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore' })
        })
    },
    aggiungiBancaleModale () {
      if (!this.isEmpty(this.bancaleModale)) this.bancaliModale.push(_clone(this.bancaleModale))
    },
    aggiungiBancaliModale: function (b) {
      for (let item of b) {
        if (!this.isEmpty(item)) this.bancaliModale.push(_clone(item))
      }
      this.$refs.risultati.hide()
    },
    removeBancale: function (idx) {
      this.bancaliModale.splice(idx, 1)
    },
    resetVars: function () {
      this.bancaleModale = {}
      this.bancaliModale = []
      this.bancaliAutocomplete = []
      this.visualizza = false
      this.searchBancali = null
      this.bancale = {}
    },
    isEmpty (obj) {
      return (obj.keys && obj.keys.length)
    }
  },
  mounted: function () {
    $EventBus.$on('unione-posizione', (o) => {
      let bancali = this.bancaliModale.map(b => {
        let bnc = { _id: b._id, cliente_id: b.cliente_id }
        return bnc
      })
      this.$plsqlMethod('gestione', 'unisci', { posizione: this.bancale.posizione, bancali: bancali })
        .then(response => {
          $EventBus.$emit('reload')
          if (response.data.bancale) {
            $EventBus.$emit('message', { type: 'success', text: 'Unione bancali terminata: è stato generato il bancale ' + response.data.bancale })
          }
          this.hide()
        })
        .catch(err => {
          $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
        })
    })
  },
  components: {
    posizione,
    'modal-risultati': modalRisultati
  }
}

export default comp
</script>

<style scoped>
  .v-list__group {
    border-bottom: 1px solid rgba(0,0,0,0.12);
  }
  .rowWrapper {
    height: auto;
    line-height: inherit;
    padding: 10px 0;
  }
  .rowWrapper-row {
    height: 90px;
    margin-top: 0!important;
  }
</style>
