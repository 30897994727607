import { render, staticRenderFns } from "./Unione.vue?vue&type=template&id=718c4202&scoped=true&"
import script from "./Unione.vue?vue&type=script&lang=js&"
export * from "./Unione.vue?vue&type=script&lang=js&"
import style0 from "./Unione.vue?vue&type=style&index=0&id=718c4202&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "718c4202",
  null
  
)

export default component.exports