<template>
  <v-dialog fullscreen v-model="visualizza" transition="dialog-bottom-transition" hide-overlay scrollable>
    <v-card>
      <v-toolbar class="grey lighten-3">
        <v-toolbar-title>Storico Prelievi</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click.stop="visualizza = false"><v-icon>close</v-icon></v-btn>
      </v-toolbar>
      <v-container fluid grid-list-xl>
        <v-layout>
          <v-flex>
            <v-alert :value="true" type="info" outline>
              Dati in <strong>Sola Lettura</strong>, per inserire data e numero d'ordine accedi a Modifica Prelievo dal bancale generato con il prelievo.
            </v-alert>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-list v-if="bancali.length">
              <template
                v-for="bancale in bancali">
                <v-list-tile avatar :key="'tile-'+bancale._id">
                  <div v-on:click.stop class="chipPosition">
                    <v-chip
                      color="green"
                      text-color="white"
                      v-if="!bancale.padre_id && bancale.posizione && bancale.posizione !== 'TMP'">
                      {{bancale.posizione}}
                    </v-chip>
                    <v-chip
                      color="grey"
                      text-color="black"
                      v-if="!bancale.padre_id && bancale.posizione && bancale.posizione === 'TMP'">
                      TMP
                    </v-chip>
                    <v-chip
                      color="yellow"
                      text-color="black"
                      v-if="!bancale.padre_id && !bancale.posizione">
                      +
                    </v-chip>
                  </div>
                  <v-list-tile-content>
                    <v-list-tile-title>
                      <span v-if="bancale.progressivo">{{bancale.num_bancale}}</span>
                      <span v-else>{{bancale._id}}</span>
                    </v-list-tile-title>
                    <v-list-tile-sub-title>
                      <span v-if="bancale.componenti.length === 1">1 componente - </span>
                      <span v-if="bancale.componenti.length != 1">{{bancale.componenti.length}} componenti - </span>
                      Qta
                      <span v-if="bancale.padre_id">{{bancale.qta_prelevata}}</span>
                      <span v-else>{{bancale.qta_totale}}</span>
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-list-tile class="mt-2" v-for="(comp, index) in bancale.prelievi" :key="'comp'+index">
                  <v-list-tile-content ma-3 style="height: 70px;">
                    <v-list-tile-title class="rowWrapper">
                      <v-layout row wrap class="rowWrapper-row">
                        <v-flex sm12 lg1>
                          <v-text-field
                            label="Opera"
                            v-model="comp.opera"
                            v-if="index === 0"
                            disabled />
                        </v-flex>
                        <v-flex sm12 lg2>
                          <v-text-field
                            label="Descrizione"
                            v-model="comp.descrizione"
                            v-if="index === 0"
                            disabled />
                        </v-flex>
                        <v-flex sm12 lg1>
                          <v-text-field
                            label="Codice"
                            v-model="comp.codice"
                            v-if="index === 0"
                            disabled />
                        </v-flex>
                        <v-flex sm12 lg2>
                          <v-text-field
                            label="Componente"
                            v-model="comp.componente"
                            v-if="index === 0"
                            disabled />
                        </v-flex>
                        <v-flex sm12 lg2>
                          <v-text-field
                            label="Note"
                            v-model="comp.note"
                            v-if="index === 0"
                            disabled />
                        </v-flex>
                        <v-flex sm12 lg1>
                          <v-text-field
                            v-model="comp.qta"
                            :label="'Qtà (su un totale di ' + (comp.qta_totale - comp.prelievi_precedenti) + ')'"
                            disabled />
                        </v-flex>
                        <v-flex sm12 lg1>
                          <v-text-field
                            v-model="comp.data_prelievo"
                            label="Data prelievo"
                            disabled />
                        </v-flex>
                        <v-flex sm12 lg2>
                          <v-text-field
                            v-model="comp.pedido"
                            label="Ordine"
                            disabled />
                        </v-flex>
                      </v-layout>
                    </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </template>
            </v-list>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { $EventBus } from '@/eventBus'

export default {
  data: () => ({
    bancali: [],
    visualizza: false
  }),
  methods: {
    show () {
      this.visualizza = true
    },
    hide () {
      this.visualizza = false
    },
    init (id) {
      this.$plsqlMethod('gestione', 'prelievi', { bancale_id: id })
        .then(response => {
          this.bancali = [response.data]
        })
        .catch(err => {
          console.log('errore client', err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore lettura prelievi' })
        })
    }
  }
}
</script>
<style scoped>
  .v-list__group {
    border-bottom: 1px solid rgba(0,0,0,0.12);
  }
  .rowWrapper {
    height: auto;
    line-height: inherit;
    padding: 10px 0;
  }
  .rowWrapper-row {
    height: 90px;
    margin-top: 0!important;
  }
  .chipPosition {
    min-width: 85px;
    text-align: center;
  }
</style>
