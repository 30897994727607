<template>
  <v-dialog fullscreen v-model="visualizza" transition="dialog-bottom-transition" hide-overlay scrollable>
    <v-card>
      <v-toolbar class="grey lighten-3">
        <v-toolbar-title>Stampa Cartelli</v-toolbar-title>
        <v-spacer />
        <v-chip small color="grey dark-3" text-color="white">
          Bancali selezionati: {{bancaliModale.length}}
        </v-chip>
        <v-btn icon @click.stop="hide()"><v-icon>close</v-icon></v-btn>
      </v-toolbar>
      <v-container fluid grid-list-xl>
        <v-flex>
          <v-layout>
            <v-flex xs12 sm6>
              <v-autocomplete
                label="Cerca ID bancale"
                :items="bancaliAutocomplete"
                item-text="_id"
                item-value="_id"
                v-model="bancaleModale"
                :loading="bancaliLoading"
                :search-input.sync="searchBancali"
                @change="aggiungiBancaleModale"
                hide-no-data
                return-object
                clearable>
                <template slot="item" slot-scope="data">
                  <v-list-tile-content>
                    <v-list-tile-title>{{data.item._id}} {{data.item.posizione}}</v-list-tile-title>
                    <v-list-tile-sub-title>{{data.item.codice}} {{data.item.descrizione}}</v-list-tile-sub-title>
                  </v-list-tile-content>
                </template>
              </v-autocomplete>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12>
              <!--inizio parte duplicata-->
              <v-list two-line v-if="bancaliModale.length">
                <v-list-group
                  class="listIcon"
                  sub-group
                  no-action
                  :value="false"
                  v-for="(bancale, index) in bancaliModale"
                  v-model="bancale.active"
                  :key="'tile-'+bancale._id">
                  <v-list-tile slot="activator" avatar>
                    <v-list-tile-avatar v-on:click.stop>
                      <v-chip
                        color="green"
                        text-color="white"
                        v-if="!bancale.padre_id && bancale.posizione && bancale.posizione !== 'TMP'">
                        {{bancale.posizione}}
                      </v-chip>
                      <v-chip
                        color="grey"
                        text-color="black"
                        v-if="!bancale.padre_id && bancale.posizione && bancale.posizione === 'TMP'">
                        TMP
                      </v-chip>
                      <v-chip
                        color="yellow"
                        text-color="black"
                        v-if="!bancale.padre_id && !bancale.posizione">
                        +
                      </v-chip>
                      <v-chip
                        color="red"
                        text-color="white"
                        v-if="bancale.padre_id">
                        In uscita
                      </v-chip>
                    </v-list-tile-avatar>
                    <v-list-tile-content class="ml-2">
                      <v-list-tile-title>
                        <span v-if="bancale.progressivo">{{bancale.num_bancale}}</span>
                        <span v-else>{{bancale._id}}</span>
                      </v-list-tile-title>
                      <v-list-tile-sub-title>
                        Qta
                        <span v-if="bancale.padre_id">{{bancale.qta_prelevata}}</span>
                        <span v-else>{{bancale.qta_totale}}</span>
                        <span v-if="bancale.prelievi"> - </span>
                        <span v-if="bancale.prelievi === 1">1 prelievo</span>
                        <span v-if="bancale.prelievi > 1">{{bancale.prelievi}} prelievi</span>
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <v-btn icon ripple>
                        <v-icon @click.stop="removeBancale(index)" color="error">delete</v-icon>
                      </v-btn>
                    </v-list-tile-action>
                  </v-list-tile>
                  <v-list-tile v-for="comp in bancale.componenti" :key="comp.componente_id">
                    <v-list-tile-content>
                      <v-list-tile-title class="rowWrapper mt">
                        <v-layout row wrap class="rowWrapper-row">
                          <v-flex sm12 lg2>
                            <v-text-field
                              label="Opera"
                              v-model="comp.opera"
                              disabled />
                          </v-flex>
                          <v-flex sm12 lg2>
                            <v-text-field
                              label="Descrizione"
                              v-model="comp.descrizione"
                              disabled />
                          </v-flex>
                          <v-flex sm12 lg2>
                            <v-text-field
                              label="Codice"
                              v-model="comp.codice"
                              disabled />
                          </v-flex>
                          <v-flex sm12 lg3>
                            <v-text-field
                              label="Componente"
                              v-model="comp.componente"
                              disabled />
                          </v-flex>
                          <v-flex sm12 lg2>
                            <v-text-field
                              label="Note"
                              v-model="comp.note"
                              disabled />
                          </v-flex>
                          <v-flex sm12 lg1>
                            <v-text-field
                              label="Qta"
                              v-model="comp.qta"
                              disabled />
                          </v-flex>
                        </v-layout>
                      </v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list-group>
              </v-list>
              <!--fine parte duplicata-->
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12 class="pa-2 mt-4">
              <v-btn
                class="ml-0"
                color="primary"
                v-if="bancaliModale.length"
                @click.native="stampa()">
                Stampa
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
/* USO :
html :
    <stampa ref="stampa" />
  js :
  import stampa from '@/components/magazzino/gestione/Stampa'

  components: {
    'stampa': stampa
  }

  this.$refs.stampa.show()
*/

import _debounce from 'lodash/debounce'
import _clone from 'lodash/clone'
import { $EventBus } from '@/eventBus'

const comp = {
  data: () => ({
    visualizza: false,
    bancaleModale: {},
    bancaliModale: [],
    bancaliAutocomplete: [],
    bancaliLoading: false,
    searchBancali: null,
    bancale: {}
  }),
  methods: {
    show () {
      this.visualizza = true
    },
    hide () {
      this.visualizza = false
    },
    stampa () {
      let bP = []
      for (let b of this.bancaliModale) {
        let cP = []
        for (let c of b.componenti) {
          cP.push({
            bancale_id: c.bancale_id,
            codice: c.codice,
            descrizione: c.descrizione,
            descrizione_lavorazione: c.componente,
            qta_effettiva: c.qta - c.qta_prelevata
          })
        }
        bP.push({ bancale_id: b._id, ragione_sociale: b.ragione_sociale, sede: b.sede, componenti: cP })
      }
      let toPrint = bP
      this.$http({
        method: 'post',
        url: '/pdf/bancali',
        responseType: 'blob',
        data: { bancali: toPrint }
      }).then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        this.$openFileNewTab(blob) // con adblocker picche ocio
      }).catch((err) => {
        console.log(err)
        $EventBus.$emit('message', { type: 'error', text: 'Impossibile creare la stampa dei bancali' })
      })
    },
    cercaBancali (val) {
      if (!val) return

      this.bancaliLoading = true

      let obj = { what: val }

      this.$plsqlMethod('gestione', 'search_by_id', obj)
        .then(response => {
          this.bancaliAutocomplete = []
          for (let b of response.data) {
            if (b.componenti[0]) {
              b.codice = b.componenti[0].codice
              b.descrizione = b.componenti[0].descrizione
            }
            if (!this.bancaliModale.find(function (el) { return el._id === b._id })) this.bancaliAutocomplete.push(b)
          }
          this.bancaliLoading = false
        })
        .catch(err => {
          console.log(err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore lettura Bancali' })
          this.bancaliLoading = false
        })
    },
    aggiungiBancaleModale (bancale) {
      if (bancale) this.bancaliModale.push(_clone(bancale))
    },
    removeBancale: function (idx) {
      this.bancaliModale.splice(idx, 1)
    },
    resetVars: function () {
      this.bancaleModale = {}
      this.bancaliModale = []
      this.bancaliAutocomplete = []
      this.visualizza = false
      this.searchBancali = null
      this.bancale = {}
    }
  },
  watch: {
    searchBancali (val) {
      this.debouncedcercaBancali(val)
    }
  },
  created: function () {
    this.debouncedcercaBancali = _debounce(this.cercaBancali, 3000)
  }
}

export default comp
</script>

<style scoped>
  .v-list__group {
    border-bottom: 1px solid rgba(0,0,0,0.12);
  }
  .rowWrapper {
    height: auto;
    line-height: inherit;
    padding: 10px 0;
  }
  .rowWrapper-row {
    height: 90px;
    margin-top: 0!important;
  }
</style>

<style>
  .listIcon .v-list__group__header__prepend-icon {
    padding: 0 0 0 16px;
  }
</style>
